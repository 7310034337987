import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import {
  Heading, SubHeading, Para, Cover, Panel, Container, HideOnMobile,
} from '../../elements';
import Next from '../../components/Next';
import Back from '../../components/Back';
import Content from '../../components/Content';
import PreviewCompatibleImage from '../../components/PreviewCompatibleImage';
import AppContainer from '../../components/AppContainer';

const Col = styled.div`
  padding-left: 20px;
`;
const Overview = ({ data: { contentfulPage: page } }) => (
  <AppContainer pageTitle={page.title}>
    <Container id="content">
      <Panel color="white">
        <Back to="/" />
        <Heading ariaRole="heading">{page.heading}</Heading>
        <SubHeading dangerouslySetInnerHTML={{ __html: page.subHeading }} />
        <Content document={page.content.json} />
        <Next to="/readership" label="Readership & reach" />
      </Panel>
      <HideOnMobile style={{ backgroundColor: 'white' }}>
        <PreviewCompatibleImage imageInfo={page.image} />
      </HideOnMobile>
    </Container>
  </AppContainer>
);

export const overviewPageQuery = graphql`
  query OverviewPageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      heading
      subHeading
      content {
        json
        childContentfulRichText {
          html
        }
      }
      image {
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`;

export default Overview;
